import { max, sum } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { HookState } from "react-use/lib/util/resolveHookState";
import create from "zustand";

import {
  BidPriceRenderer,
  CustomerRenderer,
  MonthsRenderer,
  PayoutRenderer,
  SelectedMonthsRenderer,
} from "~src/designSystem/tables/Table/renderer/custom";
import { IEnvironment } from "~src/shared/env";
import { useEnv } from "~src/shared/env/useEnv";
import { isNotUndefined, stringToBool } from "~src/shared/helpers/booleanCoercion";
import { IUseListCheckable, useListCheckable } from "~src/shared/lists/hooks/useListCheckable";
import { useListData } from "~src/shared/lists/hooks/useListData";
import { IListConfig, IListFilter, IListRowData } from "~src/shared/lists/types";
import { IListDataSource } from "~src/shared/lists/types/data";
import { IListModel } from "~src/shared/lists/types/models";
import { IFilterOp } from "~src/shared/lists/types/operators";
import { configKey } from "~src/shared/lists/utils/config";

import { getSlideableProposals } from "../components/OrderBox/getSlideableProposals";
import { useRemainingPayoutLimit } from "../hooks/useRemainingPayoutLimit";
import {
  calculateBalanceFromTradeableContract,
  calculatePayoutFromTradeableContract,
  getAvailableTermsFromMaxTerm,
} from "../utils/adjustableTerms";

type IProposalContext = {
  data: IListDataSource<IListModel.lists_tradeable_contracts>;
  checkable: IUseListCheckable;
  config: IListConfig<IListModel.lists_tradeable_contracts>;
  setConfig: React.Dispatch<HookState<IListConfig<IListModel.lists_tradeable_contracts>>>;
  remainingPayoutLimit: number;
  selectedProposals: readonly IListRowData<IListModel.lists_tradeable_contracts>[];
  selectedProposalIDs: readonly string[];
  selectedProposalsPayoutValueCents: number;
  remainingSelectedPayoutLimit: number;
  selectProposalsByMaxPayout: (maxPayout: number) => void;
  termLength: number;
  setTermLength: (term: number) => void;
  payoutAmount: number;
  feeAmount: number;
  blendedBidPrice: number;
  availableTerms: Array<number>;
};

export const ProposalsContext = React.createContext<IProposalContext | null>(null);

type IStore = {
  setSelectedTermLength: (termLength: number) => void;
  selectedTermLength: number;
  showCollectionPeriodFilter: boolean;
  collectionPeriodFilter: number;
  collectionPeriods: number[];
  setShowCollectionPeriodFilter: (showIntervalCounts: boolean) => void;
  setCollectionPeriodFilter: (period: number) => void;
  setCollectionPeriods: (periods: number[]) => void;
};

const DEFAULT_TERM_LENGTH = 12;

// TODO(johnrjj): Migrate proposal state over to zustand, will pipe through context for now
export const useProposalStore = create<IStore>((set) => ({
  showCollectionPeriodFilter: false,
  collectionPeriodFilter: -1,
  collectionPeriods: [],
  selectedTermLength: DEFAULT_TERM_LENGTH,
  setSelectedTermLength: (selectedTermLength: number) => {
    set({ selectedTermLength });
  },
  setShowCollectionPeriodFilter: (showCollectionPeriodFilter: boolean) => {
    set({ showCollectionPeriodFilter });
  },
  setCollectionPeriodFilter: (collectionPeriodFilter: number) => {
    set({ collectionPeriodFilter });
  },
  setCollectionPeriods: (collectionPeriods: number[]) => {
    set({ collectionPeriods });
  },
}));

export const ProposalsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const remainingPayoutLimit = useRemainingPayoutLimit();

  const termLength = useProposalStore((s) => s.selectedTermLength);
  const setTermLength = useProposalStore((s) => s.setSelectedTermLength);
  const collectionPeriodFilter = useProposalStore((s) => s.collectionPeriodFilter);
  const showCollectionPeriodFilter = useProposalStore((s) => s.showCollectionPeriodFilter);
  const setShowCollectionPeriodFilter = useProposalStore((s) => s.setShowCollectionPeriodFilter);
  const setCollectionPeriodFilter = useProposalStore((s) => s.setCollectionPeriodFilter);
  const setCollectionPeriods = useProposalStore((s) => s.setCollectionPeriods);

  const [config, setConfig] = useState<IListConfig<IListModel.lists_tradeable_contracts>>({
    model: IListModel.lists_tradeable_contracts,
    filters: [],
    columns: [
      {
        name: "customer_display",
        title: "Customer",
        width: "minmax(200px, 2fr)",
        renderer: CustomerRenderer,
      },
      {
        name: "mrr",
        title: "MRR",
        width: "120px",
        tooltip: "Monthly recurring revenue",
      },
      {
        name: "interval_count",
        title: "Collection Period",
        renderer: MonthsRenderer,
        width: "120px",
        tooltip: "Interval at which Pipe collects payments",
      },
      // NOTE: Dummy field, we use this to calculate the bid price on the contract level
      {
        name: "base_rate_bps",
        title: "Bid Price",
        renderer: BidPriceRenderer,
        width: "100px",
        tooltip: "Latest market price for your contract",
      },
      {
        // NOTE: max_tradeable_term_length is the default contract length and an intermediate value.
        // Actual displayed value is calculated in SelectedMonthsRenderer for custom adjustable terms
        name: "max_tradeable_term_length",
        title: "Term Length",
        renderer: SelectedMonthsRenderer,
        width: "120px",
        tooltip: "Duration of the contract to be sold",
      },
      {
        // NOTE: We use the base_balance value (not base_payout, because it is not sortable)
        // as a table placeholder and derive the actual payout in the renderer
        name: "base_balance",
        renderer: PayoutRenderer,
        title: "Payout Amount",
        width: "120px",
        tooltip: "How much you'll receive for the contract",
      },
    ],
  });

  const data = useListData(config);
  const checkable = useListCheckable();
  const env = useEnv();

  const allMaxTerms = data.getAllRows().map((row) => row.data.max_tradeable_term_length ?? 0);
  const maxTerm = getMaxPossibleTermFromTerms(allMaxTerms) ?? DEFAULT_TERM_LENGTH;

  // Filter effect
  useEffect(() => {
    const filters: IListFilter<IListModel.lists_tradeable_contracts>[] = [];

    // if the available number of filters is not set, then don't filter
    if (collectionPeriodFilter !== -1) {
      filters.push({
        column: "interval_count",
        value: collectionPeriodFilter,
        operator: IFilterOp.eq,
      });
    }

    setConfig((oldConfig) => {
      return {
        ...oldConfig,
        filters,
      };
    });
  }, [collectionPeriodFilter, showCollectionPeriodFilter]);

  useEffect(() => {
    // Only run if # of contracts change, and on init.
    // Don't update the interval counts when there is an active filter
    if (showCollectionPeriodFilter) return;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const collectionPeriods = new Set(data.getAllRows().map((row) => row.data.interval_count!));

    // only set values if more than one filter (i.e. all filters are available)
    if (collectionPeriods.size <= 1) return;

    setShowCollectionPeriodFilter(true);
    setCollectionPeriods(Array.from(collectionPeriods).sort());
    setCollectionPeriodFilter(-1);
  }, [
    showCollectionPeriodFilter,
    data,
    setShowCollectionPeriodFilter,
    setCollectionPeriods,
    setCollectionPeriodFilter,
  ]);

  // Generate available adjustable term lengths for user
  const availableTerms = useMemo(() => getAvailableTermsFromMaxTerm(maxTerm), [maxTerm]);

  const orderState = useMemo(
    () => calculateOrderState(env, remainingPayoutLimit, checkable, data, termLength),
    [env, remainingPayoutLimit, checkable, data, termLength],
  );

  // Whenever the list config or count changes, eagerly load all rows for the inbox.
  // We don't add `data.loadRows` as a dependency because that causes an infinite loop.
  // What's going on is that `data.loadRows` ends up updating the cache which causes
  // it to be re-computed. So any effect that calls `data.loadRows` and depends on it will
  // keep on being re-executed.
  const key = useMemo(() => configKey(data.config), [data.config]);
  useEffect(() => {
    data.loadAllRows();
    // NOTE(johnrjj) - Manually override lint with custom key
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, data.count]);

  // Set the max term length as default on bootstrap
  useEffect(() => {
    setTermLength(maxTerm);
  }, [maxTerm, setTermLength]);

  const value = useMemo(
    () => ({
      data,
      checkable,
      config,
      setConfig,
      termLength,
      setTermLength,
      availableTerms,
      remainingPayoutLimit,
      ...orderState,
    }),
    [
      data,
      checkable,
      config,
      termLength,
      setTermLength,
      availableTerms,
      remainingPayoutLimit,
      orderState,
    ],
  );

  return <ProposalsContext.Provider value={value}>{children}</ProposalsContext.Provider>;
};

const getMaxPossibleTermFromTerms = (terms: Array<number>) => {
  return max(terms);
};

// In future, make fee non-constant (when backend enables control of pipe-fee per vendor)
const PIPE_FEE_DECIMAL = 0.01;

const calculateOrderState = (
  env: IEnvironment,
  remainingPayoutLimit: number,
  checkable: IUseListCheckable,
  data: IListDataSource<IListModel.lists_tradeable_contracts>,
  selectedTermLength: number | undefined,
) => {
  const selectedProposalIDs = checkable.getSelected();
  const selectedProposals = selectedProposalIDs
    .map((id) => data.getRowByID(id)?.data)
    .filter(isNotUndefined);

  const selectedProposalsPayoutValueCents = sum(
    selectedProposals.map((curProposal) =>
      calculatePayoutFromTradeableContract(curProposal, selectedTermLength),
    ),
  );

  const selectedProposalsBalanceValueCents = sum(
    selectedProposals.map((curProposal) =>
      calculateBalanceFromTradeableContract(curProposal, selectedTermLength),
    ),
  );

  const blendedBidPrice = selectedProposalsPayoutValueCents / selectedProposalsBalanceValueCents;

  const remainingSelectedPayoutLimit = remainingPayoutLimit - selectedProposalsPayoutValueCents;

  const availableProposals = data.getAllRows();
  const slideableProposals = getSlideableProposals(
    availableProposals,
    remainingPayoutLimit,
    selectedTermLength,
  );

  const isSandbox = env.DOMAIN.includes("pipe-sandbox");
  const feeAmount = Math.floor(selectedProposalsPayoutValueCents * PIPE_FEE_DECIMAL);

  /**
   * Total payout amount that the vendor will receive,
   * accounting for any deductions like Pipe fees.
   *
   * If Sandbox, deduct the fee. If not, omit the fee.
   * */
  const payoutAmount = isSandbox
    ? selectedProposalsPayoutValueCents - feeAmount
    : selectedProposalsPayoutValueCents;

  // Running sum of the value of all of the proposals
  const slideableProposalsCumulativeValues = (() => {
    let lastSum = 0;
    return slideableProposals.map((prop) => {
      // const payout = calculatePayoutFromTradeableContract(prop)
      lastSum += prop.base_payout ?? 0;
      return lastSum;
    });
  })();

  const selectProposalsByMaxPayout = (maxPayoutCents: number): void => {
    // sliding above the remaining payout limit shouldn't change the state
    if (remainingPayoutLimit - maxPayoutCents < 0) {
      return;
    }

    // Find the first subscription that goes over the limit
    const firstNotAllowed = slideableProposalsCumulativeValues.findIndex((v) => v > maxPayoutCents);

    // @deprecated This works and is legacy. Deeply nested ternary but who understands??
    const relevantProposals =
      // if we go over the limit with even one subscription, we don't have any subs
      firstNotAllowed === 0
        ? []
        : // if there is no first not allowed, return all subs
        firstNotAllowed === -1
        ? slideableProposals
        : // otherwise subs are up to but not including the first not allowed
          slideableProposals.slice(0, firstNotAllowed);

    checkable.updateSelections(
      relevantProposals.map((proposal) => proposal.public_id).filter(stringToBool),
    );
  };

  return {
    selectedProposalIDs,
    selectedProposals,
    selectedProposalsPayoutValueCents,
    remainingSelectedPayoutLimit,
    selectProposalsByMaxPayout,
    payoutAmount,
    feeAmount,
    blendedBidPrice,
  };
};
